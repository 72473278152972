<template>
  <el-drawer
    :title="`批量修改${this.level == 'campaign' ? '广告系列' : '广告组'}预算和竞价策略(帖子、主页赞广告不支持批量编辑)`"
    :visible="editBudgetPriceShow"
    @close="close"
    v-loading="loading"
    size="1200px"
  >
    <div
      style="padding: 0 10px"
      class="drawerCon"
    >
      <el-tabs
        type="border-card"
        v-model="activeName"
        @tab-click="tabClick"
        style="height: 98%; margin-top: 10px; overflow-y: auto"
      >
        <el-tab-pane
          label="转化量"
          name="OFFSITE_CONVERSIONS"
        ></el-tab-pane>
        <el-tab-pane
          label="价值"
          name="VALUE"
        >
        </el-tab-pane>
        <el-row>
          <el-col :span="level == 'adset' ? 5 : 6">
            <div class="title checkbox-wrapper">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
            </div>
          </el-col>
          <el-col :span="level == 'adset' ? 5 : 6">
            <div class="title">日预算(USD)</div>
          </el-col>
          <el-col :span="level == 'adset' ? 5 : 6">
            <div class="title">总预算(USD)</div>
          </el-col>
          <el-col
            :span="4"
            v-if="level == 'adset'"
            ><div class="title">竞价类型</div></el-col
          >
          <el-col :span="level == 'adset' ? 5 : 6">
            <div class="title">竞价价格(USD)</div>
          </el-col>
        </el-row>
        <!--内容-->
        <el-row
          type="flex"
          style="margin: 10px 0"
          :gutter="20"
        >
          <!--待选广告系列-->
          <el-col :span="level == 'adset' ? 5 : 6">
            <div class="set-wrapper"></div>
          </el-col>
          <!--日预算-->
          <el-col :span="level == 'adset' ? 5 : 6">
            <div class="set-wrapper">
              <!-- <span class="el-dropdown-link">批量修改</span> -->
              <el-input
                type="text"
                placeholder="请输入"
                size="mini"
                :disabled="!priceList.some((v) => !isNaN(v.daily_budget))"
                v-model="daily_budget_obj.num"
                @change="setPrice('daily_budget', daily_budget_obj.key, daily_budget_obj.num)"
              >
                <div
                  class="toolsItem"
                  slot="prepend"
                >
                  <el-select
                    v-model="daily_budget_obj.key"
                    :disabled="!priceList.some((v) => !isNaN(v.daily_budget))"
                    @change="(v) => setPrice('daily_budget', v, daily_budget_obj.num)"
                  >
                    <el-option
                      v-for="(item, index) in numList"
                      :key="item.key"
                      :value="item.key"
                      :label="item.name"
                      >{{ item.name }}</el-option
                    >
                  </el-select>
                  <!-- <el-popover
                    placement="bottom"
                    width="160"
                    trigger="hover"
                    :disabled="!priceList.some((v) => !isNaN(v.daily_budget))"
                  >
                    <div>
                      <ul class="breakdownList">
                        <li
                          v-for="(item, index) in numList"
                          :key="item.key"
                          @click="setKey('daily_budget', item.key)"
                        >
                          <el-popover
                            placement="right-start"
                            width="80"
                            trigger="hover"
                            :tabindex="index"
                            offset="20"
                          >
                            <div
                              slot="reference"
                              class="breakdownItem"
                            >
                              <span>{{ item.name }}</span>
                              <i class="el-icon-arrow-right"></i>
                            </div>
                            <p
                              v-for="num in item.value"
                              :key="num.key"
                              class="numItem"
                              @click="setPrice('daily_budget', item.key, num.val)"
                            >
                              {{ num.label }}
                            </p>
                          </el-popover>
                        </li>
                      </ul>
                    </div>
                    <el-button
                      slot="reference"
                      type="text"
                      class="el-icon-setting"
                    ></el-button>
                  </el-popover> -->
                </div>
                <div
                  slot="append"
                  v-if="daily_budget_obj.key == 'addPercent' || daily_budget_obj.key == 'reducePercent'"
                >
                  %
                </div>
              </el-input>
            </div>
          </el-col>
          <!--总预算-->
          <el-col :span="level == 'adset' ? 5 : 6">
            <div class="set-wrapper">
              <el-input
                type="text"
                placeholder="请输入"
                size="mini"
                :disabled="!priceList.some((v) => !isNaN(v.lifetime_budget))"
                v-model="lifetime_budget_obj.num"
                @change="setPrice('lifetime_budget', lifetime_budget_obj.key, lifetime_budget_obj.num)"
              >
                <div
                  class="toolsItem"
                  slot="prepend"
                >
                  <el-select
                    v-model="lifetime_budget_obj.key"
                    :disabled="!priceList.some((v) => !isNaN(v.lifetime_budget))"
                    @change="(v) => setPrice('lifetime_budget', v, lifetime_budget_obj.num)"
                  >
                    <el-option
                      v-for="(item, index) in numList"
                      :key="item.key"
                      :value="item.key"
                      :label="item.name"
                      >{{ item.name }}</el-option
                    >
                  </el-select>
                  <!-- <el-popover
                    placement="bottom"
                    width="160"
                    trigger="hover"
                    :disabled="!priceList.some((v) => !isNaN(v.lifetime_budget))"
                  >
                    <div>
                      <ul class="breakdownList">
                        <li
                          v-for="(item, index) in numList"
                          :key="item.key"
                          @click="setKey('lifetime_budget', item.key)"
                        >
                          <el-popover
                            placement="right-start"
                            width="80"
                            trigger="hover"
                            :tabindex="index"
                            offset="20"
                          >
                            <div
                              slot="reference"
                              class="breakdownItem"
                            >
                              <span>{{ item.name }}</span>
                              <i class="el-icon-arrow-right"></i>
                            </div>
                            <p
                              v-for="num in item.value"
                              :key="num.key"
                              class="numItem"
                              @click="setPrice('lifetime_budget', item.key, num.val)"
                            >
                              {{ num.label }}
                            </p>
                          </el-popover>
                        </li>
                      </ul>
                    </div>
                    <el-button
                      slot="reference"
                      type="text"
                      class="el-icon-setting"
                    ></el-button>
                  </el-popover> -->
                </div>
                <div
                  slot="append"
                  v-if="lifetime_budget_obj.key == 'addPercent' || lifetime_budget_obj.key == 'reducePercent'"
                >
                  %
                </div>
              </el-input>
            </div>
          </el-col>
          <!-- 竞价类型 -->
          <el-col
            :span="4"
            v-if="level == 'adset'"
          >
            <div v-if="camEidtBid.flag">
              <el-select
                size="mini"
                v-model="BidStrategy"
                @change="changeBidStrategy"
              >
                <el-option
                  v-for="CampaignBidStrategy in bidStrategyList({ ...camEidtBid, optimization_goal: this.activeName })"
                  :label="CampaignBidStrategy.desc"
                  :value="CampaignBidStrategy.key"
                  :key="CampaignBidStrategy.key"
                ></el-option>
              </el-select>
            </div>
            <div v-else>--</div>
          </el-col>
          <!--竞价价格-->
          <el-col :span="level == 'adset' ? 5 : 6">
            <div class="set-wrapper">
              <el-input
                type="text"
                placeholder="请输入"
                size="mini"
                :disabled="
                  activeName == 'OFFSITE_CONVERSIONS'
                    ? !priceList.some((v) => !isNaN(v.bid_amount))
                    : !priceList.some((v) => !isNaN(v.roas_average_floor))
                "
                v-model="bid_amount_obj.num"
                @change="
                  setPrice(
                    activeName == 'OFFSITE_CONVERSIONS' ? 'bid_amount' : 'roas_average_floor',
                    bid_amount_obj.key,
                    bid_amount_obj.num,
                  )
                "
              >
                <div
                  class="toolsItem"
                  slot="prepend"
                >
                  <el-select
                    v-model="bid_amount_obj.key"
                    :disabled="
                      activeName == 'OFFSITE_CONVERSIONS'
                        ? !priceList.some((v) => !isNaN(v.bid_amount))
                        : !priceList.some((v) => !isNaN(v.roas_average_floor))
                    "
                    @change="
                      (v) =>
                        setPrice(
                          activeName == 'OFFSITE_CONVERSIONS' ? 'bid_amount' : 'roas_average_floor',
                          v,
                          bid_amount_obj.num,
                        )
                    "
                  >
                    <el-option
                      v-for="(item, index) in numList"
                      :key="item.key"
                      :value="item.key"
                      :label="item.name"
                      >{{ item.name }}</el-option
                    >
                  </el-select>
                  <!-- <el-popover
                    placement="bottom"
                    width="160"
                    trigger="hover"
                    :disabled="!priceList.some((v) => !isNaN(v.bid_amount))"
                  >
                    <div>
                      <ul class="breakdownList">
                        <li
                          v-for="(item, index) in numList"
                          :key="item.key"
                          @click="setKey('bid_amount', item.key)"
                        >
                          <el-popover
                            placement="right-start"
                            width="80"
                            trigger="hover"
                            :tabindex="index"
                            offset="20"
                          >
                            <div
                              slot="reference"
                              class="breakdownItem"
                            >
                              <span>{{ item.name }}</span>
                              <i class="el-icon-arrow-right"></i>
                            </div>
                            <p
                              v-for="num in item.value"
                              :key="num.key"
                              class="numItem"
                              @click="setPrice('bid_amount', item.key, num.val)"
                            >
                              {{ num.label }}
                            </p>
                          </el-popover>
                        </li>
                      </ul>
                    </div>
                    <el-button
                      slot="reference"
                      type="text"
                      class="el-icon-setting"
                    ></el-button>
                  </el-popover> -->
                </div>
                <div
                  slot="append"
                  v-if="bid_amount_obj.key == 'addPercent' || bid_amount_obj.key == 'reducePercent'"
                >
                  %
                </div>
              </el-input>
            </div>
          </el-col>
        </el-row>
        <el-row
          v-for="(adSeries, index) in editList"
          :key="adSeries.id"
          class="list"
          :gutter="20"
          type="flex"
          align="top"
        >
          <!--待选广告系列-->
          <el-col :span="level == 'adset' ? 5 : 6">
            <div class="selectwrap">
              <el-checkbox
                style="display: block"
                @change="(value) => setSelectList(adSeries, value)"
                :value="checkedList.includes(adSeries.id)"
              ></el-checkbox>
              <span class="ad-series-item">{{ adSeries.name }}</span>
              <!-- <p
                class="status"
                v-if="adSeries.success"
              >
                <i class="el-icon-success"></i><span>修改成功</span>
              </p> -->
            </div>
          </el-col>
          <!--日预算-->
          <el-col :span="level == 'adset' ? 5 : 6">
            <el-input
              type="text"
              size="mini"
              v-model="adSeries.daily_budget"
              :disabled="
                adSeries.old_daily_budget == '已设置总预算' ||
                adSeries.old_daily_budget == '--' ||
                !adSeries.old_daily_budget
              "
              v-to-fixed="2"
            ></el-input>
            <div
              style="color: #888; margin: 5px 25px"
              v-if="!isNaN(adSeries.old_daily_budget)"
            >
              原日预算：{{ adSeries.old_daily_budget }}
            </div>
          </el-col>
          <!--总预算-->
          <el-col :span="level == 'adset' ? 5 : 6">
            <el-input
              type="text"
              size="mini"
              v-model="adSeries.lifetime_budget"
              :disabled="
                adSeries.old_lifetime_budget == '已设置日预算' ||
                adSeries.old_lifetime_budget == '--' ||
                !adSeries.old_lifetime_budget
              "
              v-to-fixed="2"
            ></el-input>
            <div
              style="color: #888; margin: 5px 25px"
              v-if="!isNaN(adSeries.old_lifetime_budget)"
            >
              原总预算：{{ adSeries.old_lifetime_budget }}
            </div>
          </el-col>
          <!-- 竞价类型 -->
          <el-col
            :span="4"
            v-if="level == 'adset'"
          >
            <el-select
              :disabled="adSeries.is_cbo"
              v-model="adSeries.bid_strategy"
              @change="setBidStrategy(adSeries.bid_strategy, adSeries)"
              size="mini"
            >
              <el-option
                v-for="CampaignBidStrategy in bidStrategyList({ ...adSeries })"
                :label="CampaignBidStrategy.desc"
                :value="CampaignBidStrategy.key"
                :key="CampaignBidStrategy.key"
              ></el-option>
            </el-select>
          </el-col>
          <!--竞价价格-->
          <el-col
            :span="level == 'adset' ? 5 : 6"
            v-if="activeName == 'OFFSITE_CONVERSIONS'"
          >
            <el-input
              type="text"
              size="mini"
              v-model="adSeries.bid_amount"
              v-if="adSeries.bid_strategy != 'LOWEST_COST_WITHOUT_CAP'"
              :disabled="level == 'campaign'"
              v-to-fixed="2"
            ></el-input>
            <span v-else>--</span>
            <div
              style="color: #888; margin: 5px 25px"
              v-if="!isNaN(adSeries.old_bid_amount)"
            >
              原竞价价格：{{ adSeries.old_bid_amount }}
            </div>
          </el-col>
          <el-col
            :span="level == 'adset' ? 5 : 6"
            v-else
          >
            <el-input
              type="text"
              size="mini"
              v-model="adSeries.roas_average_floor"
              v-if="adSeries.bid_strategy != 'LOWEST_COST_WITHOUT_CAP'"
              :disabled="level == 'campaign'"
              v-to-fixed="2"
            ></el-input>
            <span v-else>--</span>
            <div
              style="color: #888; margin: 5px 25px"
              v-if="!isNaN(adSeries.roas_average_floor)"
            >
              原roas：{{ adSeries.old_roas_average_floor }}
            </div>
          </el-col>
        </el-row>
      </el-tabs>
    </div>
    <!-- </el-form> -->
    <div class="demo-drawer__footer">
      <p></p>
      <div></div>
      <p>
        <el-button @click="close">返回</el-button>
        <el-button
          type="primary"
          @click="sure"
          id="batchEditBudgetEvent"
          >提交</el-button
        >
      </p>
    </div>
  </el-drawer>
</template>

<script>
import { pricingInfo, mutatePricing } from '@/api/adManagement.js';
import { mapGetters } from 'vuex';
export default {
  props: {
    editBudgetPriceShow: {
      type: Boolean,
      default: false,
    },
    selectedRowScopes: {
      type: Array,
      default: () => [],
    },
    level: {
      type: String,
      defalut: 'campaign',
    },
  },
  data() {
    return {
      showModifyAdSeriesBudgetPrice: false, // 是否展示修改广告系列预算和竞价的弹窗
      adSeriesArr: [], // 全部广告系列的数组
      // isIndeterminate: false,  // 用于控制全选框的样式
      checkAll: false, // 用于控制是否全选
      // 修改预算与竞价的表单
      formObj: {},
      numList: [
        {
          name: '按固定金额',
          key: 'equal',
          value: [
            { label: '1.00', val: 1 },
            { label: '2.00', val: 2 },
            { label: '3.00', val: 3 },
            { label: '4.00', val: 4 },
            { label: '5.00', val: 5 },
          ],
        },
        {
          name: '按固定金额增加',
          key: 'add',
          value: [
            { label: '1.00', val: 1 },
            { label: '2.00', val: 2 },
            { label: '3.00', val: 3 },
            { label: '4.00', val: 4 },
            { label: '5.00', val: 5 },
          ],
        },
        {
          name: '按固定金额减少',
          key: 'reduce',
          value: [
            { label: '-1.00', val: 1 },
            { label: '-2.00', val: 2 },
            { label: '-3.00', val: 3 },
            { label: '-4.00', val: 4 },
            { label: '-5.00', val: 5 },
          ],
        },
        {
          name: '按百分比增加',
          key: 'addPercent',
          value: [
            { label: '10%', val: 0.1 },
            { label: '20%', val: 0.2 },
            { label: '30%', val: 0.3 },
            { label: '40%', val: 0.4 },
            { label: '50%', val: 0.5 },
          ],
        },
        {
          name: '按百分比减少',
          key: 'reducePercent',
          value: [
            { label: '-10%', val: 0.1 },
            { label: '-20%', val: 0.2 },
            { label: '-30%', val: 0.3 },
            { label: '-40%', val: 0.4 },
            { label: '-50%', val: 0.5 },
          ],
        },
      ],
      bid_amount_obj: {
        key: 'equal',
        type: 'bid_amount',
        num: 0,
      },
      daily_budget_obj: {
        key: 'equal',
        type: 'daily_budget',
        num: 0,
      },
      lifetime_budget_obj: {
        key: 'equal',
        type: 'lifetime_budget',
        num: 0,
      },
      adSeriesArr: [], // 全部广告系列的数组
      priceList: [],
      checkedList: [], //  选择的列表
      loading: false,
      BidStrategy: '', // 竞价类型
      offsitList: [], //竞价目标是转化量的
      valueList: [], //竞价目标是价值的
      activeName: 'OFFSITE_CONVERSIONS',
    };
  },
  watch: {
    editBudgetPriceShow() {
      if (this.editBudgetPriceShow) {
        this.getList();
      }
    },
  },
  computed: {
    ...mapGetters(['initData']),
    ...mapGetters('initData', ['getBidStrategyList', 'getOptimizationGoals']),
    headers() {
      return {
        adAccountId: this.accountId,
        thirdLoginUserId: this.selectedRowScopes[0].rowThirdNum,
      };
    },
    isIndeterminate() {
      let count = this.checkedList.length;
      if (count > 0 && count < this.editList.length) {
        return true;
      } else {
        return false;
      }
    },
    accountId() {
      if (this.selectedRowScopes.length && this.selectedRowScopes[0].rowAccountId) {
        return this.selectedRowScopes[0].rowAccountId;
      } else {
        return this.$store.getters.accountId;
      }
    },
    objectivesCampaignBidStrategy() {
      let keys = {};
      this.initData.ad.CampaignBidStrategy.map((item) => {
        item.objectives.map((objective) => {
          if (keys[objective.key]) {
            keys[objective.key].push({ ...item });
          } else {
            keys[objective.key] = [{ ...item }];
          }
        });
      });
      console.log(keys);
      return keys;
    },
    // 系列类型
    serverType() {
      if (this.checkedList.length && this.level == 'adset') {
        return this.priceList.find((item) => item.id == this.checkedList[0]).objective;
      }
      return '';
    },
    // 是否是同一个系列类型
    isOneServerType() {
      if (this.level == 'adset') {
        let objective = this.priceList
          .filter((item) => {
            return this.checkedList.includes(item.id);
          })
          .map((item) => item.objective);

        return new Set(objective).size <= 1;
      }
    },
    // 默认显示的tab
    // activeName: {
    //   get() {
    //     if (!this.priceList.filter((item) => item.optimization_goal == 'OFFSITE_CONVERSIONS').length) {
    //       return 'VALUE';
    //     } else {
    //       return 'OFFSITE_CONVERSIONS';
    //     }
    //   },
    //   set(v) {},
    // },
    // 当前卡片显示的数据
    editList() {
      return this.priceList.filter((item) => item.optimization_goal == this.activeName);
    },
    // 选中数据，优化目标，是否是只能购物广告
    camEidtBid() {
      let msg = {
        flag: false,
      };
      if (this.level == 'adset') {
        let checkedListMsg = this.editList.filter((item) => this.checkedList.includes(item.id));
        let smartType = checkedListMsg.map((item) => item.smart_promotion_type);
        let objective = checkedListMsg.map((item) => item.objective);
        let cbo = checkedListMsg.map((item) => item.is_cbo);
        msg.flag = new Set(smartType).size <= 1 && new Set(objective).size <= 1 && cbo.every((v) => !v);
        if (msg.flag) {
          msg.objective = objective[0];
          msg.smart_promotion_type = smartType[0];
        }
      } else {
        msg.flag = false;
      }
      return msg;
    },
  },
  methods: {
    open() {
      this.showModifyAdSeriesBudgetPrice = true;
    },
    init(arr = []) {
      // console.log(arr)
      this.adSeriesArr = arr;
    },
    tabClick(v) {
      console.log(v);
      this.activeName = v.name;
      this.checkedList = this.editList.map((item) => item.id);
      this.checkAll = true;
      this.BidStrategy = null;
      this.bid_amount_obj.num = 0;
      this.bid_amount_obj.key = 'equal';
      this.daily_budget_obj.num = 0;
      this.daily_budget_obj.key = 'equal';
      this.lifetime_budget_obj.num = 0;
      this.lifetime_budget_obj.key = 'equal';
    },
    // 获取竞价策略
    bidStrategyList({ optimization_goal, smart_promotion_type, objective }) {
      //   console.log(is_cbo, optimization_goal, smart_promotion_type);
      return this.getBidStrategyList({
        objective: objective,
        optimization_goal: optimization_goal,
        smart_promotion_type: smart_promotion_type,
      });
    },
    confirmModifyAdSeriesBudgetPrice() {
      this.$message.success('确认修改广告系列的预算和竞价');
    },
    close() {
      // this.$message.success('关闭弹窗')
      this.$emit('close-drawer');
      this.bid_amount_obj = { key: 'equal', type: 'bid_amount', num: 0 };
      this.daily_budget_obj = { key: 'equal', type: 'daily_budget', num: 0 };
      this.lifetime_budget_obj = { key: 'equal', type: 'lifetime_budget', num: 0 };
    },
    // 全选
    handleCheckAllChange(v) {
      console.log(this.editList);
      this.checkedList = v ? this.editList.map((item, index) => item.id) : [];
      this.checkAll = v;
      // this.isIndeterminate = false
    },
    // 广告系列改变
    setSelectList(item, v) {
      const index = this.checkedList.findIndex((i) => i == item.id);

      if (index >= 0) {
        this.checkedList.splice(index, 1);
      } else {
        this.checkedList.push(item.id);
      }
    },
    getList() {
      let params = {
        ids: this.selectedRowScopes.map((v) => v.id),
        level: this.level,
        accountId: this.accountId,
      };
      this.loading = true;
      pricingInfo(JSON.stringify(params), this.selectedRowScopes[0].rowThirdNum).then((res) => {
        // console.log(res);
        this.loading = false;
        this.priceList = res.data;
        this.checkAll = true;
        this.priceList.map((item) => {
          item.old_daily_budget = item.daily_budget ? this.$store.getters.toFormatedMoney(item.daily_budget).value : '';
          item.old_lifetime_budget = item.lifetime_budget
            ? this.$store.getters.toFormatedMoney(item.lifetime_budget).value
            : '';
          item.old_bid_amount = item.bid_amount ? this.$store.getters.toFormatedMoney(item.bid_amount).value : '';
          item.old_roas_average_floor = item.roas_average_floor ? item.roas_average_floor : '';
          item.daily_budget = item.daily_budget
            ? this.$store.getters.toFormatedMoney(item.daily_budget).value
            : item.lifetime_budget
            ? '已设置总预算'
            : '--';
          item.lifetime_budget = item.lifetime_budget
            ? this.$store.getters.toFormatedMoney(item.lifetime_budget).value
            : item.daily_budget
            ? '已设置日预算'
            : '--';
          item.bid_amount = item.bid_amount ? this.$store.getters.toFormatedMoney(item.bid_amount).value : '--';
        });
        this.offsitList = this.priceList.filter((item) => item.optimization_goal == 'OFFSITE_CONVERSIONS');
        this.valueList = this.priceList.filter((item) => item.optimization_goal == 'VALUE');
        this.checkedList = this.editList.map((item) => item.id);
      });
    },
    // 修改类型
    setKey(type, key) {
      this[type + '_obj'] = {
        type: type,
        key: key,
        num: 0,
      };
    },
    // 批量修改价格
    setPrice(type, key, num) {
      if (isNaN(num) || !num) {
        this.$message({
          type: 'warning',
          message: '请输入正确的数字',
        });
        return;
      }
      this[type + '_obj'] = {
        type: type,
        key: key,
        num: num,
      };

      this.priceList.map((item) => {
        if (type == 'bid_amount' && item.bid_strategy == 'LOWEST_COST_WITHOUT_CAP') {
          return;
        }
        if (this.checkedList.includes(item.id) && !isNaN(item[type])) {
          // 按固定金额
          if (key == 'equal') {
            item[type] = num;
          }
          // 按固定金额增加
          if (key == 'add') {
            item[type] = item['old_' + type] + num / 1;
          }
          // 减少
          if (key == 'reduce') {
            item[type] = item['old_' + type] - num / 1;
          }
          // 按固定比例增加
          if (key == 'addPercent') {
            item[type] = item['old_' + type] / 1 + ((item['old_' + type] / 1) * (num / 100)) / 1 / 1;
          }
          // 按固定比例减少
          if (key == 'reducePercent') {
            item[type] = item['old_' + type] / 1 - ((item['old_' + type] / 1) * (num / 100)) / 1 / 1;
          }
          item[type] = item[type] ? (item[type] / 1).toFixed(2) : item[type];
        }
      });
    },
    // 修改竞价策略
    setBidStrategy(v, item) {
      this.priceList.map((adset) => {
        if (v == 'LOWEST_COST_WITHOUT_CAP' && adset.id == item.id) {
          adset.bid_amount = null;
          adset.roas_average_floor = null;
        }
      });
    },
    sure() {
      let list = JSON.parse(JSON.stringify(this.priceList));

      list = list.map((item) => {
        return {
          daily_budget: item.daily_budget ? this.$store.getters.toBaseMoney(item.daily_budget) : null,
          lifetime_budget: item.lifetime_budget ? this.$store.getters.toBaseMoney(item.lifetime_budget) : null,
          bid_amount: item.bid_amount ? this.$store.getters.toBaseMoney(item.bid_amount) : null,
          before_amount: item.old_bid_amount ? this.$store.getters.toBaseMoney(item.old_bid_amount) : null,
          before_roas_average_floor: item.old_roas_average_floor ? item.old_roas_average_floor : null,
          roas_average_floor: item.roas_average_floor ? item.roas_average_floor / 1 : null,
          before_lifetime_budget: item.old_lifetime_budget
            ? this.$store.getters.toBaseMoney(item.old_lifetime_budget)
            : null,
          before_daily_budget: item.old_daily_budget ? this.$store.getters.toBaseMoney(item.old_daily_budget) : null,
          level: this.level,
          id: item.id,
          name: item.name,
          bid_strategy: item.bid_strategy,
        };
      });
      let params = {
        objects: list,
        level: this.level,
      };
      this.$showLoading();
      mutatePricing(JSON.stringify(params), this.headers).then((res) => {
        // console.log(res);
        this.$hideLoading();
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '修改成功',
          });
          res.data.objects.map((item) => {
            this.priceList.map((key, i) => {
              if (key.id == item.id) {
                key.success = true;
                this.$set(this.priceList, i, key);
              }
            });
            this.close();
          });
        }

        this.$emit(
          'refresh-table',
          this.priceList.filter((v) => v.success),
        );
      });
    },
    // 批量修改竞价类型
    changeBidStrategy(value) {
      console.log(value);
      this.priceList.map((item, index) => {
        if (this.checkedList.includes(item.id)) {
          let price = {
            ...item,
            bid_strategy: value,
            bid_amount: value == 'LOWEST_COST_WITHOUT_CAP' ? '--' : '',
          };
          console.log('price', price);
          this.$set(this.priceList, index, price);
        }
      });
    },
  },
};
</script>
<style lang="scss">
.set-wrapper {
  .el-input-group__append,
  .el-input-group__prepend {
    padding: 0;
  }
}
.demo-drawer__footer {
  display: flex;
  justify-content: space-between;
}
</style>
<style lang="scss" scoped>
.status {
  font-size: 14px;
  margin-top: 5px;
  color: #67c23a;
  position: absolute;
  left: 20px;
  top: 30px;
  width: 100%;
}
.el-input {
  text-align: right;
}
.numItem {
  line-height: 1.5;
  margin: 10px 0;
  cursor: pointer;
}
.el-input-group__prepend .el-button {
  margin: -10px 8px;
}

.breakdownList {
  li {
    line-height: 1.5;
    margin: 10px 0;
    cursor: pointer;
    .breakdownItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.title {
  display: flex;
  align-items: center;
  height: 34px;
  font-size: 14px;
  color: rgb(176, 179, 182);
  // padding: 0 20px;
}
.el-row {
  padding: 10px 0;
  .el-col {
    padding: 0 10px;
  }
}

.list {
  margin: 10px 0 !important;
  .el-col {
    padding: 0 5px;
    font-size: 14px;
    // color: #666;
  }
  .selectwrap {
    display: flex;
    align-items: center;
    position: relative;
  }
  .ad-series-item {
    position: absolute;
    max-width: 220px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 25px;
  }
}
.set-wrapper {
  font-size: 14px;
  display: flex;
  align-items: center;
  span {
    flex: 60px 0 0;
  }
}
.toolsItem {
  width: 140px;
  .el-select {
    width: 100%;
    padding: 0;
    margin: 0;
    height: 26px;
  }
}
</style>
